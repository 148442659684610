import { Card } from 'antd';
import Styled from 'styled-components';
import {
  BasicFormWrapper as OriginalBasicFormWrapper,
  Main as OriginalMain,
  ImportStyleWrap as OriginalImportStyleWrap,
} from './styled';

const BasicFormWrapper = Styled(OriginalBasicFormWrapper)`
  .ant-divider-horizontal.ant-divider-with-text {
    // margin-top: 32px;
    font-size: 17px;
  }

  .ant-form-item {
    .ant-form-item-label {
      label {
          margin-bottom: 0;
      }
    }
    .ant-form-item-control-input {
      .ant-input-number {
        width: auto;

        &.ant-input-number-sm {
          padding: 3px;
        }
        
        &:focus, 
        &.ant-input-number-focused {
          border-color: ${({ theme }) => theme['primary-color']} !important;
        }
        
        // .ant-input-number-input {
        //   min-width: 150px;
        // }
      }
      button.ant-btn-sm {
        height: 32px;
        padding: 0 12px;
      }
      button.ant-switch {
        height: 18px;
      }
      .ant-input-affix-wrapper{
          padding: 0;
      }
    }
  }

  /* form */
  .ant-select-selection-placeholder,
  .ant-form-item .ant-form-item-control-input input::placeholder, 
  .ant-form-item .ant-form-item-control-input textarea::placeholder {
    color: ${({ theme }) => theme['light-gray-color']};
  }

  .ant-input,
  .ant-form-item .ant-form-item-control-input .ant-picker-input input {
    padding: 12px 20px;
  }
  
  .ant-input,
  .ant-select-selector {
    color: ${({ theme }) => theme['text-color']} !important;
    border-color: ${({ theme }) => theme['border-color-normal']} !important;
    
    &:focus {
      border-color: ${({ theme }) => theme['primary-color']} !important;
    }
  }

  .ant-select-focused {
    .ant-select-selector {
      border-color: ${({ theme }) => theme['primary-color']} !important;
    }
  }
  
  .ant-checkbox-inner {
    border-color: ${({ theme }) => theme['border-color-deep']} !important;
  }
  
  .ant-picker.ant-picker-middle {
    .ant-picker-input input {
      padding: 0 11px;
    }
  }

  .ant-select{

    &.ant-select-multiple{
      .ant-select-selection-item{
        ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 0px;
      }
    }

    .ant-select-selection-item{
        ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 0px !important;
    }

    .ant-select-multiple .ant-select-selection-placeholder,
    .ant-select-single .ant-select-selector .ant-select-selection-search {
      left: 20px;
    }

    .ant-select-multiple .ant-select-selector {
      padding: 5px 20px !important;
    }
  }

  .ant-radio-button-wrapper-checked a {
    color: #FFF !important;
  }
   
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: ${({ theme }) => theme['danger-color']} !important;
  }

  // .ant-input,
  // .ant-picker,
  // .ant-radio-group-small .ant-radio-button-wrapper,
  // .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  // .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  //   height: 36px !important;
  // }
  
  /* form inline */
  .ant-form-inline {
    gap: 14px;

    .ant-space-item {
      .ant-form-item {
        margin: 0;
      }
    }

    .ant-select-multiple .ant-select-selection-placeholder,
    .ant-select-single .ant-select-selector .ant-select-selection-search {
      left: 11px;
    }
    
    .ant-radio-group-small .ant-radio-button-wrapper,
    .ant-select-single .ant-select-selector .ant-select-selection-item, 
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 34px !important;
    }
    
    .ant-input,
    .ant-picker,
    .ant-select .ant-select-selection-item, 
    .ant-select-multiple .ant-select-selector {
      padding: 0 11px !important;
    }

    .ant-form-item {
      &:not(:last-child){
        margin-bottom: 0;
      }
      .ant-form-item-label {
        display: flex
        align-items: center;
      }
    }

    .ant-form-item .ant-form-item-control-input .ant-picker-input input {
      padding: 6px 0;
    }
  }

  .ant-form-item {
    &:not(:last-child){
      margin-bottom: 12px;
    }
    &:last-child{
        margin-bottom: 0;
    }
  }
  
  /* form sm */
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 26px 0 7px;
  }

  /* form md */
  .ant-form-item {
    .ant-radio-group-middle {
      .ant-radio-button-wrapper {
        height: 34px;
        line-height: 32px;
        padding: 0 12px;
      }
    }
  }

  /* form sm */
  .ant-form-item {
    .ant-select-sm{
      .ant-select-selector{
        padding: 0 10px;
        height: 36px !important;
        .ant-select-selection-item{
          line-height: 34px !important;
          padding: 0 !important;
        }
        .ant-select-selection-placeholder{
          line-height: 36px !important;
        }
        .ant-select-selection-search-input {
          height: 36px;
        }
      }
    }
    
    .ant-select-sm.ant-pagination-options-size-changer{
      .ant-select-selector{
        padding: 0 10px;
        height: 25px !important;
        .ant-select-selection-item{
          line-height: 23px !important;
          padding: 0 !important;
        }
        .ant-select-selection-placeholder{
          line-height: 25px !important;
        }
        .ant-select-selection-search-input {
          height: 25px;
        }
      }
    }

    .ant-form-item-control-input{
      min-height: 36px;

      input[type="password"], 
      input[type="text"], 
      .ant-picker-input input {
        &.ant-input-sm {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }

      // button {
      //   height: 36px;
      // }
    }
  }

  .ant-picker.date-time-picker {
    width: 190px;
  }

  .ant-picker.date-time-picker.ant-picker-small {
    width: 170px;
  }

  /* typography */
  .ant-typography.ant-typography-light {
    color: ${({ theme }) => theme['light-color']};
  }

  /* description */
  .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content.no-padding,
  .ant-descriptions-item-content.p-0 {
    padding: 0 !important;
  }

  .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content.p-b,
  .ant-descriptions-item-content.p-b {
    padding-bottom: 12px !important;
  }
`;

const Main = Styled(OriginalMain)`
  @media only screen and (max-width: 991px){
    min-height: unset;
  }

  /* card */
  & > .ant-card {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, .05);
    line-height: initial;
  }

  .ant-divider-inner-text {
    color: ${({ theme }) => theme['primary-color']};
  }

  .ant-card-head {
    .ant-card-extra a {
      color: ${({ theme }) => theme['link-color']};
      
      &:hover {
        color: ${({ theme }) => theme['link-hover']};
      }
    }
    .ant-picker.ant-picker-range {
      width: 270px;
    }
  }

  /* collapse */
  .ant-collapse.ant-collapse-icon-position-left .ant-collapse-header {
    padding: 12px 16px;
  }

  .ant-collapse > .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    font-size: 12px;
    position: initial;
    padding: 0;
    margin-right: 12px;
  }

  /* comments */
  .ant-comment {
    .ant-comment-content-author-name a {
      font-size: ${({ theme }) => theme['font-size-base']};
      color: ${({ theme }) => theme['link-color']};
    }
    .ant-comment-content-author > a, 
    .ant-comment-content-author > span {
      font-size: ${({ theme }) => theme['font-size-base']};
      color: ${({ theme }) => theme['link-color']};
    }
    .ant-comment-content-detail {
      background: ${({ theme }) => theme['bg-color-normal']};
      padding: 14px;
      border-radius: 5px;
    }
    .ant-comment-actions li span {
      color: unset;
    }
    .ant-comment-content-detail textarea {
      min-height: unset;
      resize: block;
    }
  }

  .ant-avatar-lg {
    img {
      width: 40px;
      height: 40px;
    }
  }

  /* status label */
  .status-label {
    color: ${({ theme }) => theme['info-color']};
    background-color: ${({ theme }) => theme['info-color']}15;

    &.success {
      color: ${({ theme }) => theme['success-color']};
      background: ${({ theme }) => theme['success-color']}10;
    }
    &.warning {
        color: ${({ theme }) => theme['warning-color']};
        background: ${({ theme }) => theme['warning-color']}10;
    }
    &.danger {
        color: ${({ theme }) => theme['danger-color']};
        background: ${({ theme }) => theme['danger-color']}10;
    }
  }

  /* table */
  .ant-table {
    color: ${({ theme }) => theme['gray-color']};
  }
  .ant-table-container {
    .ant-dropdown-trigger:not(.ant-table-filter-trigger) {
        color: ${({ theme }) => theme['link-color']};
    }
    a {
      color: ${({ theme }) => theme['link-color']};

      &:hover {
        color: ${({ theme }) => theme['link-hover']};
      }
    }
  }

  /* typography */
  .ant-typography.ant-typography-light {
    color: ${({ theme }) => theme['light-color']};
  }

  /* descriptions */
  .ant-descriptions-item-content {
    color: #757575
  }

  /* button */
  .ant-btn-default {
    border-width: 1px;

    &:hover {
      color: ${({ theme }) => theme['primary-color']};
      border-color: ${({ theme }) => theme['primary-color']};
    }
  }

  /* list */
  .ant-list-items {
    padding-top: unset;
  }
`;

const ImportStyleWrap = Styled(OriginalImportStyleWrap)`
  .sDash_import-inner {
    .ant-upload.ant-upload-drag {
      min-height: unset;
      background: ${({ theme }) => `${theme['link-color']}0D`};
      border-color: ${({ theme }) => theme['link-color']};
      transition: opacity .3s;
      
      &:hover {
        border-color: ${({ theme }) => theme['link-hover']};
      }

      .ant-upload-drag-icon svg, 
      .ant-upload-drag-icon i {
        color: ${({ theme }) => theme['link-color']};
      }
    
      .ant-upload-text {
        color: ${({ theme }) => theme['gray-color']};
      }

      .ant-upload-btn {
        height: 200px
      }

      &.ant-upload-disabled {
        opacity: 30%;
      }
    }

    .ant-upload-hint{
      span {
          color: ${({ theme }) => theme['link-color']};
      }
  }
    .ant-upload-list{
      .ant-upload-list-item{
        background-color: #fff;
      }
      .ant-upload-list-item-card-actions.picture{
        top: 22px;

        .ant-btn-text {
          color: ${({ theme }) => theme['gray-solid']};
        }
      }
    }
  }
`;

const ChartWrapper = Styled.div`
  .progressbar-wrap {
    .arcprogress {
      margin: 0 auto;
    }
  }
  h5.ant-typography, 
  div.ant-typography-h5, 
  div.ant-typography-h5 > textarea, 
  .ant-typography h5 {
    margin-bottom: 1em;
  }
  .chart-wrap {
    width: 160px;
    height: 160px;
    margin: 0 auto 24px auto;
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      width: 90px;
      margin-bottom: 0;
      display: inline-block;
      transform: translate(-50%, -50%);
      span {
        font-size: 24px;
        display: block;
        color: #000;
      }
    }
    canvas {
      margin: 0 auto;
    }
  }
  .list-description {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px 32px;
    width: 100%;

    .list-description__item {
      white-space: nowrap;
      text-align: center;

      h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .ant-badge-status-text {
        color: ${({ theme }) => theme['gray-color']};
      }
    }
  }
`;

const EntityCard = Styled(Card)`
  background: unset !important;
  box-shadow: unset !important;

  .ant-card-body {
    background: unset;
    padding: 0 !important;
  }

  .ant-card-meta {
    margin: 0;

    .ant-card-meta-title {
      line-height: normal;
    }
  }

  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0;
  }

  .ant-card-meta-description > .ant-space {
    display: flex;
    flex-wrap: unset;
  }
`;

const TableWrapper = Styled.div`
  .ant-table-tbody > tr > td.row-custom-column {
    padding: 0;
  }

  .row-custom-column .readed,
  .row-custom-column .unreaded,
  .row-custom-column .row-error,
  .row-custom-column .row-warning,
  .row-custom-column .row-info,
  .row-custom-column .row-success {
    padding: 16px;
  }

  .row-readed {
    color: ${({ theme }) => theme['gray-color']} !important;
    background-color: ${({ theme }) => theme['layout-body-background']} !important;
  }

  .row-unreaded {
    font-weight: bold;
  }
    
  .row-error {
    background-color: ${({ theme }) => `${theme['error-color']}0D`};
    
    a,
    .ant-table-container a {
      color: ${({ theme }) => theme['error-color']} !important;
    }
  }
  .row-warning {
    background-color: ${({ theme }) => `${theme['warning-color']}0D`};
    
    a,
    .ant-table-container a {
      color: ${({ theme }) => theme['warning-color']} !important;
    }
  }
  .row-info {
    background-color: ${({ theme }) => `${theme['info-color']}0D`};
    
    a,
    .ant-table-container a {
      color: ${({ theme }) => theme['info-color']} !important;
    }
  }
  .row-success {
    background-color: ${({ theme }) => `${theme['success-color']}0D`};
    
    a,
    .ant-table-container a {
      color: ${({ theme }) => theme['success-color']} !important;
    }
  }
`;

export { BasicFormWrapper, Main, ImportStyleWrap, ChartWrapper, EntityCard, TableWrapper };
